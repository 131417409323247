import { GET_LOCAL_DATA, GET_LOCAL_DATA_FAIL, GET_LOCAL_DATA_SUCCESS } from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  localizations: [],
}

const localization = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOCAL_DATA_SUCCESS:
      state = {
        ...state,
        localizations: action.payload,
      }
      break
    case GET_LOCAL_DATA_FAIL:
      state = { ...state, error: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default localization
