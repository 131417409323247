import usFlag from "../assets/images/flags/us.jpg";
import french from "../assets/images/flags/french.jpg";


const languages = {
  fr: {
    label: "French",
    flag: french,
  },
  en: {
    label: "English",
    flag: usFlag,
  },
}

export default languages;
