import { DELETE_AGENT_GOAL_FAIL, DELETE_AGENT_GOAL_SUCCESS, GET_AGENT_GOAL, GET_AGENT_GOAL_FAIL, GET_AGENT_GOAL_SUCCESS, GET_AGENT_TRANSACTION, GET_AGENT_TRANSACTION_FAIL, GET_AGENT_TRANSACTION_SUCCESS } from "./actionTypes"

const initialState = {
  error: "",
  loading: true,
  goals:[],
  transactions:[]
}

const Transaction = (state = initialState, action) => {
  switch (action.type) {
    case GET_AGENT_GOAL:
      return { ...state, loading: true }
    case GET_AGENT_GOAL_SUCCESS:
      return { ...state, goals: action.payload, loading: false }
    case GET_AGENT_GOAL_FAIL:
      return { ...state, error: action.payload, loading: false }
    case DELETE_AGENT_GOAL_SUCCESS:
      return { ...state, goal: action.payload }
    case DELETE_AGENT_GOAL_FAIL:
      return { ...state, error: action.payload }
    case GET_AGENT_TRANSACTION:
      return { ...state, loading: true }
    case GET_AGENT_TRANSACTION_SUCCESS:
      return { ...state, transactions: action.payload, loading: false }
    case GET_AGENT_TRANSACTION_FAIL:
      return { ...state, error: action.payload, loading: false }
    default:
      state = { ...state }
      break
  }
  return state
}

export default Transaction
