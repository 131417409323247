export const ADD_BROKER_RESOURCE = "ADD_BROKER_RESOURCE"
export const ADD_BROKER_RESOURCE_SUCCESS = "ADD_BROKER_RESOURCE_SUCCESS"
export const ADD_BROKER_RESOURCE_ERROR = "ADD_BROKER_RESOURCE_ERROR"


export const GET_BROKER_RESOURCE = "GET_BROKER_RESOURCE"
export const GET_BROKER_RESOURCE_SUCCESS = "GET_BROKER_RESOURCE_SUCCESS"
export const GET_BROKER_RESOURCE_FAIL = "GET_BROKER_RESOURCE_FAIL"

export const UPDATE_BROKER_RESOURCE = "UPDATE_BROKER_RESOURCE"
export const UPDATE_BROKER_RESOURCE_SUCCESS = "UPDATE_BROKER_RESOURCE_SUCCESS"
export const UPDATE_BROKER_RESOURCE_FAIL = "UPDATE_BROKER_RESOURCE_FAIL"



export const DELETE_BROKER_RESOURCE = "DELETE_BROKER_RESOURCE"
export const DELETE_BROKER_RESOURCE_SUCCESS = "DELETE_BROKER_RESOURCE_SUCCESS"
export const DELETE_BROKER_RESOURCE_ERROR = "DELETE_BROKER_RESOURCE_ERROR"

