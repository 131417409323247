import { ADD_BORKER_EVENT, ADD_BORKER_EVENT_ERROR, ADD_BORKER_EVENT_SUCCESS, GET_BORKER_EVENT_FAIL, GET_BORKER_EVENT_SUCCESS } from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  events: []
}

const Event = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BORKER_EVENT_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case ADD_BORKER_EVENT_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    case GET_BORKER_EVENT_SUCCESS:
      return {
        ...state,
        events: action.payload,
      }

    case GET_BORKER_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default Event
