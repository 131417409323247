export const ADD_REVIEW_DISTRIBUTION = "ADD_REVIEW_DISTRIBUTION"
export const ADD_REVIEW_DISTRIBUTION_SUCCESS = "ADD_REVIEW_DISTRIBUTION_SUCCESS"
export const ADD_REVIEW_DISTRIBUTION_ERROR = "ADD_REVIEW_DISTRIBUTION_ERROR"


export const GET_REVIEW_DISTRIBUTION = "GET_REVIEW_DISTRIBUTION"
export const GET_REVIEW_DISTRIBUTION_SUCCESS = "GET_REVIEW_DISTRIBUTION_SUCCESS"
export const GET_REVIEW_DISTRIBUTION_FAIL = "GET_REVIEW_DISTRIBUTION_FAIL"

export const UPDATE_REVIEW_DISTRIBUTION = "UPDATE_REVIEW_DISTRIBUTION"
export const UPDATE_REVIEW_DISTRIBUTION_SUCCESS = "UPDATE_REVIEW_DISTRIBUTION_SUCCESS"
export const UPDATE_REVIEW_DISTRIBUTION_FAIL = "UPDATE_REVIEW_DISTRIBUTION_FAIL"



export const DELETE_REVIEW_DISTRIBUTION = "DELETE_REVIEW_DISTRIBUTION"
export const DELETE_REVIEW_DISTRIBUTION_SUCCESS = "DELETE_BROKER_RESOURCE_SUCCESS"
export const DELETE_REVIEW_DISTRIBUTION_ERROR = "DELETE_BROKER_RESOURCE_ERROR"

export const UPLOAD_TEST_FILE = "UPLOAD_TEST_FILE"
export const UPLOAD_TEST_FILE_SUCCESS = "UPLOAD_TEST_FILE_SUCCESS"
export const UPLOAD_TEST_FILE_ERROR = "UPLOAD_TEST_FILE_ERROR"

