/* Leads */
export const SET_SELECTED_ROWS = "SET_SELECTED_ROWS"


export const GET_CAMPAIGN = "GET_CAMPAIGN"
export const GET_CAMPAIGN_SUCCESS = "GET_CAMPAIGN_SUCCESS"
export const GET_CAMPAIGN_FAIL = "GET_CAMPAIGN_FAIL"

export const SEND_PROJECT_CAMPAIGN = 'SEND_PROJECT_CAMPAIGN'
export const SEND_PROJECT_CAMPAIGN_SUCCESS = 'SEND_PROJECT_CAMPAIGN_SUCCESS'
export const SEND_PROJECT_CAMPAIGN_FAIL = 'SEND_PROJECT_CAMPAIGN_FAIL'

export const PREVIEW_SEND_PROJECT_CAMPAIGN = 'SEND_PROJECT_CAMPAIGN'
export const PREVIEW_SEND_PROJECT_CAMPAIGN_SUCCESS = 'SEND_PROJECT_CAMPAIGN_SUCCESS'
export const PREVIEW_SEND_PROJECT_CAMPAIGN_FAIL = 'SEND_PROJECT_CAMPAIGN_FAIL'