import { ADD_BROKER_RESOURCE_ERROR, ADD_BROKER_RESOURCE_SUCCESS, GET_BROKER_RESOURCE_FAIL, GET_BROKER_RESOURCE_SUCCESS } from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  resources: []
}

const Resource = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BROKER_RESOURCE_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case ADD_BROKER_RESOURCE_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    case GET_BROKER_RESOURCE_SUCCESS:
      return {
        ...state,
        resources: action.payload,
      }

    case GET_BROKER_RESOURCE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default Resource
