export const ADD_BUILDER = "ADD_BUILDER"
export const ADD_BUILDER_SUCCESS = "ADD_BUILDER_SUCCESS"
export const ADD_BUILDER_ERROR = "ADD_BUILDER_ERROR"


export const GET_BUILDER = "GET_BUILDER"
export const GET_BUILDER_SUCCESS = "GET_BUILDER_SUCCESS"
export const GET_BUILDER_FAIL = "GET_BUILDER_FAIL"

export const DELETE_BUILDER = "DELETE_BUILDER"
export const DELETE_BUILDER_SUCCESS = "DELETE_BUILDER_SUCCESS"
export const DELETE_BUILDER_FAIL = "DELETE_BUILDER_FAIL"

export const GET_BUILDER_DETAIL = "GET_BUILDER_DETAIL"
export const GET_BUILDER_DETAIL_SUCCESS = "GET_BUILDER_DETAIL_SUCCESS"
export const GET_BUILDER_DETAIL_FAIL = "GET_BUILDER_DETAIL_FAIL"



export const ADD_BUILDER_DEPART = "ADD_BUILDER_DEPART"
export const ADD_BUILDER_DEPART_SUCCESS = "ADD_BUILDER_DEPART_SUCCESS"
export const ADD_BUILDER_DEPART_ERROR = "ADD_BUILDER_DEPART_ERROR"

