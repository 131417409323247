import {
  ADD_BUILDER,
  ADD_BUILDER_SUCCESS,
  ADD_BUILDER_ERROR,
  GET_BUILDER_SUCCESS,
  GET_BUILDER_FAIL,
  GET_BUILDER_DETAIL_SUCCESS,
  GET_BUILDER_DETAIL_FAIL,
  ADD_BUILDER_DEPART,
  ADD_BUILDER_DEPART_SUCCESS,
  GET_BUILDER,
  GET_BUILDER_DETAIL,
  DELETE_BUILDER_SUCCESS,
  DELETE_BUILDER_FAIL
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  builders: [],
  departs: [],
  builderData: {},
  
}

const Builder = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BUILDER:
      
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_BUILDER_SUCCESS:
      console.log('ADD_BUILDER_SUCCESS');
      state = {
        ...state,
        loading: false,
      }
      break
    case ADD_BUILDER_ERROR:
      //console.log('ADD_BUILDER_ERROR');
      state = { ...state, error: action.payload, loading: false }
      break
      case GET_BUILDER:
        return {
          ...state,
          loading:true
        }
      case GET_BUILDER_SUCCESS:
        return {
          ...state,
          builders: action.payload,
          loading:false
        }
  
      case GET_BUILDER_FAIL:
        return {
          ...state,
          error: action.payload,
          loading:false
        }
        case GET_BUILDER_DETAIL:
        return {
          ...state,
          loading:true
        }
      case GET_BUILDER_DETAIL_SUCCESS:
        return {
          ...state,
          builderData: action.payload,
          loading:false
        }
  
      case GET_BUILDER_DETAIL_FAIL:
        return {
          ...state,
          error: action.payload,
          loading:false
        }
      
      case ADD_BUILDER_DEPART:
    
        state = {
          ...state,
          loading: true,
        }
        break
      case ADD_BUILDER_DEPART_SUCCESS:
        
        state = {
          ...state,
          loading: false,
        }
        break
      case ADD_BUILDER_ERROR:
      
        state = { ...state, error: action.payload, loading: false }
        break  
        case DELETE_BUILDER_SUCCESS:
          return { ...state, type: action.payload }
        case DELETE_BUILDER_FAIL:
          return { ...state, error: action.payload }
    default:
      state = { ...state }
      break
  }
  return state
}

export default Builder
