import { ADD_LEAD_APP_FAIL, ADD_LEAD_APP_SUCCESS, ADD_LEAD_NOTE_FAIL, ADD_LEAD_NOTE_SUCCESS,ADD_LEAD_LIST_FAIL, ADD_LEAD_LIST_SUCCESS, CREATE_LEAD, CREATE_LEAD_FAIL, CREATE_LEAD_SUCCESS, GET_LEADS, GET_LEADS_ERROR, GET_LEADS_SUCCESS, GET_LEAD_ALL_ACT_FAIL, GET_LEAD_ALL_ACT_SUCCESS, GET_LEAD_APP_FAIL, GET_LEAD_APP_SUCCESS, GET_LEAD_CALLLOG_FAIL, GET_LEAD_CALLLOG_SUCCESS, GET_LEAD_DETAIL_FAIL, GET_LEAD_DETAIL_SUCCESS, GET_LEAD_EMAILLOG_FAIL, GET_LEAD_EMAILLOG_SUCCESS, GET_LEAD_FOLLOW_FAIL, GET_LEAD_FOLLOW_SUCCESS, GET_LEAD_NOTE_FAIL, GET_LEAD_NOTE_SUCCESS, GET_LEAD_LIST_FAIL, GET_LEAD_LIST_SUCCESS, POST_LEAD_FOLLOW_FAIL, POST_LEAD_FOLLOW_SUCCESS, UPDATE_LEAD_FAIL, UPDATE_LEAD_SUCCESS } from "./actionTypes"

const initialState = {

  error: "",
  loading: true,
  leads: [],
  appointments: [],
  leadNotes: [],
  leadFollows: [],
  activities: [],
  allLeads: [],
  calllogs:[],
  emaillogs:[],
  leadList:[]

}

const leads = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_LEAD:

      state = {
        ...state,
        loading: true,
      }
      break
    case CREATE_LEAD_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case CREATE_LEAD_FAIL:
      state = { ...state, error: action.payload, loading: false }
      break
    case GET_LEADS:
      return { ...state, loading: true }
    case GET_LEADS_SUCCESS:
      return { ...state, allLeads: action.payload, loading: false }
    case GET_LEADS_ERROR:
      return { ...state, error: action.payload, loading: false }
    case GET_LEAD_DETAIL_SUCCESS:

      return {
        ...state,
        leads: action.payload,
      }

    case GET_LEAD_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_LEAD_APP_SUCCESS:

      state = {
        ...state,
        loading: false,
      }
      break
    case ADD_LEAD_APP_FAIL:
      state = { ...state, error: action.payload, loading: false }
      break

    case GET_LEAD_APP_SUCCESS:
      return {
        ...state,
        appointments: action.payload,
      }

    case GET_LEAD_APP_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_LEAD_NOTE_SUCCESS:

      state = {
        ...state,
        loading: false,
      }
      break
    case ADD_LEAD_NOTE_FAIL:
      state = { ...state, error: action.payload, loading: false }
      break
      case ADD_LEAD_LIST_SUCCESS:

      state = {
        ...state,
        loading: false,
      }
      break
    case ADD_LEAD_LIST_FAIL:
      state = { ...state, error: action.payload, loading: false }
      break
    case GET_LEAD_NOTE_SUCCESS:

      return {
        ...state,
        leadNotes: action.payload,
      }

    case GET_LEAD_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

      case GET_LEAD_LIST_SUCCESS:

      return {
        ...state,
        leadList: action.payload,
      }

    case GET_LEAD_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    case POST_LEAD_FOLLOW_SUCCESS:

      state = {
        ...state,
        loading: false,
      }
      break
    case POST_LEAD_FOLLOW_FAIL:
      state = { ...state, error: action.payload, loading: false }
      break
    case GET_LEAD_FOLLOW_SUCCESS:
      return {
        ...state,
        leadFollows: action.payload,
      }

    case GET_LEAD_FOLLOW_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_LEAD_ALL_ACT_SUCCESS:
      return {
        ...state,
        activities: action.payload,
      }

    case GET_LEAD_ALL_ACT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_LEAD_SUCCESS:
      return {
        ...state,
        leads: state.leads.map(lead =>
          lead.id.toString() === action.payload.id.toString()
            ? { lead, ...action.payload }
            : lead
        ),
      }
    case UPDATE_LEAD_FAIL:
      return { ...state, error: action.payload }
    case GET_LEAD_CALLLOG_SUCCESS:
      return {
        ...state,
        calllogs: action.payload,
      }

    case GET_LEAD_CALLLOG_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_LEAD_EMAILLOG_SUCCESS:
      return {
        ...state,
        emaillogs: action.payload,
      }

    case GET_LEAD_EMAILLOG_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default leads
