import { ADD_NEW_TASK_FAIL, ADD_NEW_TASK_SUCCESS, DELETE_TASK_FAIL, DELETE_TASK_SUCCESS, GET_TASK, GET_TASK_FAIL, GET_TASK_SUCCESS, UPDATE_TASK_FAIL, UPDATE_TASK_SUCCESS } from "./actionTypes"

const initialState = {
  error: "",
  loading: true,
  taskes: [],
  
  
}

const Task = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NEW_TASK_SUCCESS:
      state = { ...state, loading: false }
      break
    case ADD_NEW_TASK_FAIL:
      state = { ...state, error: action.payload, loading: false }
      break
    case GET_TASK:
      return {
        ...state,
        loading:true,
      }
      case GET_TASK_SUCCESS:
        return {
          ...state,
          taskes: action.payload,
          loading:false,
        }
  
      case GET_TASK_FAIL:
        return {
          ...state,
          error: action.payload,
          loading:false,
        }
        case UPDATE_TASK_SUCCESS:
          return {
            ...state,
            taskes: state.taskes.map(task =>
              task.id.toString() === action.payload.id.toString()
                ? { task, ...action.payload }
                : task
            ),
          }
        case UPDATE_TASK_FAIL:
          return { ...state, error: action.payload }
        case DELETE_TASK_SUCCESS:
          return { ...state, task: action.payload }
        case DELETE_TASK_FAIL:
          return { ...state, error: action.payload }
    default:
      state = { ...state }
      break
  }
  return state
}

export default Task
