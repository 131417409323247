export const ADD_BORKER_EVENT = "ADD_BORKER_EVENT"
export const ADD_BORKER_EVENT_SUCCESS = "ADD_BORKER_EVENT_SUCCESS"
export const ADD_BORKER_EVENT_ERROR = "ADD_BORKER_EVENT_ERROR"


export const GET_BORKER_EVENT = "GET_BORKER_EVENT"
export const GET_BORKER_EVENT_SUCCESS = "GET_BORKER_EVENT_SUCCESS"
export const GET_BORKER_EVENT_FAIL = "GET_BORKER_EVENT_FAIL"

export const UPDATE_BORKER_EVENT = "UPDATE_BORKER_EVENT"
export const UPDATE_BORKER_EVENT_SUCCESS = "UPDATE_BORKER_EVENT_SUCCESS"
export const UPDATE_BORKER_EVENT_FAIL = "UPDATE_BORKER_EVENT_FAIL"



export const DELETE_BORKER_EVENT = "DELETE_BORKER_EVENT"
export const DELETE_BORKER_EVENT_SUCCESS = "DELETE_BORKER_EVENT_SUCCESS"
export const DELETE_BORKER_EVENT_ERROR = "DELETE_BORKER_EVENT_ERROR"

