export const ADD_BROKER_MESSAGE = "ADD_BROKER_MESSAGE"
export const ADD_BROKER_MESSAGE_SUCCESS = "ADD_BROKER_MESSAGE_SUCCESS"
export const ADD_BROKER_MESSAGE_ERROR = "ADD_BROKER_MESSAGE_ERROR"


export const GET_BROKER_MESSAGE = "GET_BROKER_MESSAGE"
export const GET_BROKER_MESSAGE_SUCCESS = "GET_BROKER_MESSAGE_SUCCESS"
export const GET_BROKER_MESSAGE_FAIL = "GET_BROKER_MESSAGE_FAIL"

export const UPDATE_BROKER_MESSAGE = "UPDATE_BROKER_MESSAGE"
export const UPDATE_BROKER_MESSAGE_SUCCESS = "UPDATE_BROKER_MESSAGE_SUCCESS"
export const UPDATE_BROKER_MESSAGE_FAIL = "UPDATE_BROKER_MESSAGE_FAIL"



export const DELETE_BROKER_MESSAGE = "DELETE_BROKER_MESSAGE"
export const DELETE_BROKER_MESSAGE_SUCCESS = "DELETE_BROKER_MESSAGE_SUCCESS"
export const DELETE_BROKER_MESSAGE_ERROR = "DELETE_BROKER_MESSAGE_ERROR"

