export const CREATE_AGENT_GOAL = "CREATE_AGENT_GOAL"
export const CREATE_AGENT_GOAL_SUCCESS = "CREATE_AGENT_GOAL_SUCCESS"
export const CREATE_AGENT_GOAL_FAIL = "CREATE_AGENT_GOAL_FAIL"


export const GET_AGENT_GOAL = "GET_AGENT_GOAL"
export const GET_AGENT_GOAL_SUCCESS = "GET_AGENT_GOAL_SUCCESS"
export const GET_AGENT_GOAL_FAIL = "GET_AGENT_GOAL_FAIL"


export const DELETE_AGENT_GOAL = "DELETE_AGENT_GOAL"
export const DELETE_AGENT_GOAL_SUCCESS = "DELETE_AGENT_GOAL_SUCCESS"
export const DELETE_AGENT_GOAL_FAIL = "DELETE_AGENT_GOAL_FAIL"



export const GET_AGENT_TRANSACTION = "GET_AGENT_TRANSACTION"
export const GET_AGENT_TRANSACTION_SUCCESS = "GET_AGENT_TRANSACTION_SUCCESS"
export const GET_AGENT_TRANSACTION_FAIL = "GET_AGENT_TRANSACTION_FAIL"







