import { ADD_REVIEW_DISTRIBUTION_ERROR, ADD_REVIEW_DISTRIBUTION_SUCCESS, GET_REVIEW_DISTRIBUTION_FAIL, GET_REVIEW_DISTRIBUTION_SUCCESS } from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  reviewsSources: []
}

const ReviewSource = (state = initialState, action) => {
  switch (action.type) {
    case ADD_REVIEW_DISTRIBUTION_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case ADD_REVIEW_DISTRIBUTION_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    case GET_REVIEW_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        reviewsSources: action.payload,
      }

    case GET_REVIEW_DISTRIBUTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default ReviewSource
