import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import uuidValidate from 'uuid-validate'; 
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
        templateData : ''
    };

    this.formRef = React.createRef();
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
   
  }
  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    const projectId = params.projectId;
    const isValid = uuidValidate(projectId,4);
    if (!isValid) {
        this.props.history.push('/');
    }
    axios.get(`https://rscoreapi.azurewebsites.net/api/ProjectLandingPage/byprojectguid?guid=${projectId}`, {
  }).then(res => {
    const templateData = res.data;
    this.setState({ templateData });
  })
  .catch(error => {
    console.error("There was an error fetching the data!", error);
  });
  }

  componentDidUpdate() {
    if (this.formRef.current) {
      this.formRef.current.addEventListener('submit', this.handleFormSubmit);
    }
  }

  componentWillUnmount() {
    if (this.formRef.current) {
      this.formRef.current.removeEventListener('submit', this.handleFormSubmit);
    }
  }

  handleFormSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    const datt = {
      projectCampaignId: 0,
      agentId: this.state.templateData.agentId,
      campaignProjectId: this.state.templateData.landingPageProjectId,
      firstName: formData.get('first_name'),
      lastName: formData.get('last_name'),
      email: formData.get('email'),
      mobile: formData.get('phone_number'),
      withAgent: formData.get('isrealtor') === 'true' ? 'true' : 'false' 
    };
    toast.loading("Please wait...");
    axios.post('https://rscoreapi.azurewebsites.net/api/ProjectCampaignLead', datt)
      .then(response => {
        toast.dismiss();
                    
        if(response.data.success){
          toast.success("Thank you");
        }else{
          response.data.validationErrors.forEach(error => {
            toast.error(error);
          });
        }
      
      })
      .catch(error => {
        console.error("There was an error submitting the form!", error);
      });
  }
  render() {
    const { templateData } = this.state;
    return (
     
      <React.Fragment>
        <ToastContainer autoClose={2000} />
          <MetaTags>
            <title>{templateData.landingPageTitle	} | Realty Space</title>
          </MetaTags>
          <div
          ref={this.formRef}
          dangerouslySetInnerHTML={{ __html: templateData.landingPageFullContent }}
        />
      </React.Fragment>
    );
  }
}
LandingPage.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  history:PropTypes.object
};
export default connect()(withTranslation()(LandingPage));

