import { GET_CAMPAIGN, GET_CAMPAIGN_FAIL, GET_CAMPAIGN_SUCCESS, SET_SELECTED_ROWS } from "./actionTypes"

const initialState = {

  error: "",
  loading: true,
  selectedRows: []
}

const campaign = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: action.payload
      }
      case GET_CAMPAIGN:
        return {
          ...state,
          loading:true,
        }
      case GET_CAMPAIGN_SUCCESS:
        return {
          ...state,
          campaigns: action.payload,
          loading:false,
        }
  
      case GET_CAMPAIGN_FAIL:
        return {
          ...state,
          error: action.payload,
          loading:false,
        }
    default:
      state = { ...state }
      break
  }
  return state
}

export default campaign
