export const GET_TEMPLATES = "GET_TEMPLATES"
export const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS"
export const GET_TEMPLATES_FAIL = "GET_TEMPLATES_FAIL"


export const GET_TEMPLATE_BY_TYPE = "GET_TEMPLATE_BY_TYPE"
export const GET_TEMPLATE_BY_TYPE_SUCCESS = "GET_TEMPLATE_BY_TYPE_SUCCESS"
export const GET_TEMPLATE_BY_TYPE_FAIL = "GET_TEMPLATE_BY_TYPE_FAIL"


export const GET_TEMPLATE_BY_ID = "GET_TEMPLATE_BY_ID"
export const GET_TEMPLATE_BY_ID_SUCCESS = "GET_TEMPLATE_BY_ID_SUCCESS"
export const GET_TEMPLATE_BY_ID_FAIL = "GET_TEMPLATE_BY_ID_FAIL"


export const GET_TEMPLATE_BY_PROJECT_ID = "GET_TEMPLATE_BY_PROJECT_ID"
export const GET_TEMPLATE_BY_PROJECT_ID_SUCCESS = "GET_TEMPLATE_BY_PROJECT_ID_SUCCESS"
export const GET_TEMPLATE_BY_PROJECT_ID_FAIL = "GET_TEMPLATE_BY_PROJECT_ID_FAIL"


export const CREATE_MASTER_EMAIL_TEMPLATE = "CREATE_MASTER_EMAIL_TEMPLATE"
export const CREATE_MASTER_EMAIL_TEMPLATE_SUCCESS = "CREATE_MASTER_EMAIL_TEMPLATE_SUCCESS"
export const CREATE_MASTER_EMAIL_TEMPLATE_FAIL = "CREATE_MASTER_EMAIL_TEMPLATE_FAIL"

export const GET_MASTER_EMAIL_TEMPLATE = "GET_MASTER_EMAIL_TEMPLATE"
export const GET_MASTER_EMAIL_TEMPLATE_SUCCESS = "GET_MASTER_EMAIL_TEMPLATE_SUCCESS"
export const GET_MASTER_EMAIL_TEMPLATE_FAIL = "GET_MASTER_EMAIL_TEMPLATE_FAIL"


export const GET_MASTER_EMAIL_TEMPLATE_BY_ID = "GET_MASTER_EMAIL_TEMPLATE_BY_ID"
export const GET_MASTER_EMAIL_TEMPLATE_BY_ID_SUCCESS = "GET_MASTER_EMAIL_TEMPLATE_BY_ID_SUCCESS"
export const GET_MASTER_EMAIL_TEMPLATE_BY_ID_FAIL = "GET_MASTER_EMAIL_TEMPLATE_BY_ID_FAIL"

export const LOAD_MASTER_EMAIL_TEMPLATE = "LOAD_MASTER_EMAIL_TEMPLATE"
export const LOAD_MASTER_EMAIL_TEMPLATE_SUCCESS = "LOAD_MASTER_EMAIL_TEMPLATE_SUCCESS"
export const LOAD_MASTER_EMAIL_TEMPLATE_FAIL = "LOAD_MASTER_EMAIL_TEMPLATE_FAIL"





