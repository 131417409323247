import {
  GET_CONTACT_DETAIL_SUCCESS,
  GET_CONTACT_DETAIL_FAIL,
  ADD_CONTACT,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_ERROR,
  GET_BUYER_QUES_DETAIL,
  ADD_BUYER_QUES,
  ADD_BUYER_QUES_SUCCESS,
  ADD_BUYER_QUES_FAIL,
  ADD_TAB_NOTE,
  ADD_TAB_NOTE_SUCCESS,
  ADD_TAB_NOTE_FAIL,
  ADD_TAB_EMAIL_SUCCESS,
  ADD_TAB_EMAIL_FAIL,
  ADD_TAB_EMAIL,
  ADD_TAB_CALL,
  ADD_TAB_CALL_SUCCESS,
  ADD_TAB_CALL_FAIL,
  GET_TAB_CALL,
  GET_TAB_CALL_FAIL,
  GET_TAB_CALL_SUCCESS,
  GET_TAB_EMAIL_SUCCESS,
  GET_TAB_EMAIL_FAIL,
  GET_TAB_NOTE_SUCCESS,
  GET_TAB_NOTE_FAIL,
  ADD_CONTACT_APP_SUCCESS,
  ADD_CONTACT_APP_FAIL,
  GET_CONTACT_APP_SUCCESS,
  GET_CONTACT_APP_FAIL,
  GET_CONTACT_APP_DETAIL_FAIL,
  GET_ALL_CONTACT_ACTI_SUCCESS,
  GET_ALL_CONTACT_ACTIVITY_FAIL,
  GET_CONTACT_APP_DETAIL_SUCCESS,
  ADD_OPHOUSE_LEAD_SUCCESS,
  ADD_OPHOUSE_LEAD_FAIL
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  contacts: [],
  contactDetail: {},
  callLogs: [],
  emailLogs: [],
  noteLogs: [],
  appointments: [],
  appDetails: [],
  allactivities: []

}

const Contact = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CONTACT:

      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_CONTACT_SUCCESS:

      state = {
        ...state,
        loading: false,
      }
      break
    case ADD_CONTACT_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    case GET_CONTACT_DETAIL_SUCCESS:
      return {
        ...state,
        contactDetail: action.payload,
      }

    case GET_CONTACT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_BUYER_QUES_DETAIL:
      return {
        ...state,
        questionDetail: action.payload,
      }
    case ADD_BUYER_QUES:

      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_BUYER_QUES_SUCCESS:

      state = {
        ...state,
        loading: false,
      }
      break
    case ADD_BUYER_QUES_FAIL:

      state = { ...state, error: action.payload, loading: false }
      break

    case ADD_TAB_NOTE:

      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_TAB_NOTE_SUCCESS:

      state = {
        ...state,
        loading: false,
      }
      break
    case ADD_TAB_NOTE_FAIL:

      state = { ...state, error: action.payload, loading: false }
      break
    case ADD_TAB_EMAIL:

      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_TAB_EMAIL_SUCCESS:

      state = {
        ...state,
        loading: false,
      }
      break
    case ADD_TAB_EMAIL_FAIL:

      state = { ...state, error: action.payload, loading: false }
      break

    case ADD_TAB_CALL:

      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_TAB_CALL_SUCCESS:

      state = {
        ...state,
        loading: false,
      }
      break
    case ADD_TAB_CALL_FAIL:

      state = { ...state, error: action.payload, loading: false }
      break

    case GET_TAB_CALL_SUCCESS:
      //console.log(action.payload);
      return {
        ...state,
        callLogs: action.payload,
      }

    case GET_TAB_CALL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_TAB_EMAIL_SUCCESS:
      //console.log(action.payload);
      return {
        ...state,
        emailLogs: action.payload,
      }

    case GET_TAB_EMAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    case GET_TAB_NOTE_SUCCESS:
      //console.log(action.payload);
      return {
        ...state,
        noteLogs: action.payload,
      }

    case GET_TAB_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_CONTACT_APP_SUCCESS:

      state = {
        ...state,
        loading: false,
      }
      break
    case ADD_CONTACT_APP_FAIL:
      state = { ...state, error: action.payload, loading: false }
      break

    case GET_CONTACT_APP_SUCCESS:
      //console.log(action.payload);
      return {
        ...state,
        appointments: action.payload,
      }

    case GET_CONTACT_APP_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CONTACT_APP_DETAIL_SUCCESS:
    
      return {
        ...state,
        appDetails: action.payload,
      }

    case GET_CONTACT_APP_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_ALL_CONTACT_ACTI_SUCCESS:
      //console.log(action.payload);
      return {
        ...state,
        allactivities: action.payload,
      }

    case GET_ALL_CONTACT_ACTIVITY_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    
    default:
      state = { ...state }
      break
  }
  return state
}

export default Contact
